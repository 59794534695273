@import url("https://fonts.googleapis.com/css2?family=Bangers&family=Roboto&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: "Roboto", sans-serif;
  position: relative;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Bangers", sans-serif;
  letter-spacing: 0.1rem;
  font-weight: 400;
}

.container {
  max-width: 1500px;
  margin: 0 auto;
  padding: 0 1.5rem;
}

::-webkit-scrollbar {
  width: 3px;
}

::-webkit-scrollbar-track {
  background: #222;
}

::-webkit-scrollbar-thumb {
  background: gold;
}

* {
  scrollbar-width: auto;
  scrollbar-color: gold #222;
}
