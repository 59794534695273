.podcastPlayerSection {
  position: relative;
  width: 100%;
  bottom: 0;
  color: gold;
  background-color: #222;
  padding: 0.5rem 1rem 1rem 1rem;
  max-height: 100px;
  overflow: hidden;
  transition: max-height ease-in-out 1s;
}

.podcastPlayerSection.show {
  max-height: 700px;
}

.menuButton {
  display: block;
  margin: 0.5rem auto 1rem auto;
  width: 100px;
  /* height: 8px; */
  padding: 2px 0;
  border-radius: 5px;
  background-color: #aaa;
  border: none;
  cursor: pointer;
  transition: background-color ease-in-out 250ms;
}

.menuButton:hover {
  background-color: gold;
}

.helpButton {
  position: absolute;
  right: 1rem;
  top: 0.75rem;
  padding: 0.25rem 0.5rem;
  border-radius: 50%;
  background-color: transparent;
  color: gold;
  border: 1px solid gold;
}

.helpDialog {
  padding: 1rem 0.5rem;
  border: 1px dashed #aaa;
  margin-bottom: 1rem;
  display: none;
}

.helpDialogOpen {
  display: block;
}

.helpDialog p {
  text-align: center;
  color: #aaa;
}

.helpDialog p:not(:last-of-type) {
  margin-bottom: 0.5rem;
}

.helpDialog p span {
  color: gold;
}

.details {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.title {
  text-wrap: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 1rem;
  word-wrap: break-word;
  overflow-wrap: break-word;
  width: 100%;
  margin-bottom: 0.5rem;
  min-height: 1.1rem;
}

.pubDate i {
  margin-right: 0.5rem;
}

.time i {
  margin-left: 0.5rem;
}

.imageContainer {
  display: flex;
  justify-content: center;
  gap: 2rem;
  margin-bottom: 1rem;
}

.description {
  color: #fff;
  font-size: 1.1rem;
  display: none;
}

.description p {
  margin-bottom: 1rem;
}

.description ul,
.description ol {
  margin: 0.25rem 0 1rem 2rem;
}

.image {
  max-width: 100%;
  max-height: 250px;
}

.podcastPlayerSection input[type="range"] {
  width: 100%;
  max-width: 300px;
  margin: 1.5rem auto 1rem auto;
  display: block;
  -webkit-appearance: none;
  appearance: none;
  background: #444;
  border-radius: 15px;
  overflow: hidden;
}

.podcastPlayerSection input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 10px;
  height: 30px;
  background: gold;
  cursor: pointer;
}

.playerButtons {
  display: flex;
  justify-content: space-between;
  max-width: 250px;
  margin: 1rem auto;
}

.playerButtons button {
  position: relative;
  font-size: 2rem;
  height: 40px;
  aspect-ratio: 1 / 1;
  color: gold;
  background-color: transparent;
  border: none;
  transition: color ease-in-out 250ms;
  cursor: pointer;
}

.playerButtons button:nth-of-type(1)::after,
.playerButtons button:nth-of-type(3)::after {
  content: "15";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 1.2rem;
  margin-left: 0.25rem;
  pointer-events: none;
}

.playerButtons button:nth-of-type(3)::after {
  left: -1.4rem;
  margin-left: 0;
}

.playerButtons button:hover {
  color: red;
}

.playerButtons button:disabled {
  opacity: 0.5;
  pointer-events: none;
}

.clearPlaylist {
  display: block;
  margin: 1.5rem auto 1rem auto;
  padding: 0.5rem 1rem;
  border: 1px solid #aaa;
  border-radius: 5px;
  background-color: transparent;
  color: #aaa;
  cursor: pointer;
  transition: background-color ease-in-out 250ms;
}

.clearPlaylist:hover {
  background-color: gold;
  color: #000;
}

@media (width >= 768px) {
  .infoContainer {
    display: flex;
    margin: 0 auto;
    max-width: 1100px;
  }

  .description {
    display: block;
    padding: 0 2rem;
    max-width: 550px;
  }

  .imageContainer {
    width: 50%;
  }

  .image {
    max-height: 390px;
    height: auto;
    aspect-ratio: 1 / 1;
    object-fit: contain;
  }
}
