.readingListSection {
  background: linear-gradient(180deg, #222, #444);
  background-size: cover;
  background-attachment: fixed;
  padding: 3rem 0;
  min-height: calc(100vh - 155px);
}

.readingListTitle {
  color: gold;
  font-size: 2.5rem;
  text-align: center;
  margin: 3rem 0 1rem 0;
  padding-bottom: 0.75rem;
  border-bottom: 2px solid red;
}

.readingListDescription {
  color: #f2f2f2;
  max-width: 900px;
  margin: 1rem auto 3rem auto;
  text-align: center;
  font-size: 1.125rem;
  padding: 0 1.5rem;
}

.readingListDescription p:not(:last-child) {
  margin-bottom: 0.5rem;
}

.episodes {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 4rem;
  margin: 0 auto;
  margin-bottom: 2rem;
  margin-top: 3rem;
  max-width: 1600px;
  padding: 0 1rem;
}

.coverArt {
  width: 100%;
  height: 100%;
  display: block;
}

.episodeTitle {
  color: gold;
  margin-bottom: 1.5rem;
}

.flipCard {
  background-color: transparent;
  width: 300px;
  height: 300px;
  perspective: 1000px;
  color: gold;
  cursor: pointer;
}

.flipCardInner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.flipCardFront,
.flipCardBack {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  overflow: auto;
}

.flipCardFront {
  background-color: #333;
  color: gold;
}

.flipCardBack {
  background-color: #444;
  color: gold;
  transform: rotateY(180deg);
  padding: 1rem 0.5rem;
  user-select: none;
  overflow: hidden;
}

.flip {
  transform: rotateY(180deg);
}

.flip .flipCardBack {
  overflow-y: auto;
}

.readingListLink,
.readingListText {
  color: gold;
  font-size: 1.125rem;
  margin-top: 1rem;
  display: block;
  text-align: center;
  text-decoration: none;
  transition: color ease-in-out 0.3s;
}

.readingListLink:hover {
  color: red;
}

.sortContainer {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  justify-content: flex-end;
  gap: 1rem;
  margin-bottom: 2rem;
}

.sortContainer p {
  color: #ffd900cc;
  display: inline;
  margin-right: 1rem;
}

.orderButton {
  font-size: 1.1rem;
  background: none;
  border: none;
  color: gold;
  cursor: pointer;
  font-family: "Bangers", sans-serif;
  letter-spacing: 3px;
  transition: background-color ease-in-out 250ms, color ease-in-out 250ms;
}

.orderButton:hover {
  color: #ffd900cc;
}

.episodeTypeSelect {
  border: none;
  font-family: "Bangers", sans-serif;
  letter-spacing: 3px;
  font-size: 1.1rem;
  background-color: transparent;
  color: gold;
}

.episodeTypeSelect option {
  background-color: #333;
  letter-spacing: 3px;
}

.pagination {
  display: flex;
  list-style: none;
  justify-content: space-around;
  max-width: 500px;
  margin: 0 auto;
}

.pagination button {
  font-size: 1.25rem;
  background: none;
  border: none;
  color: gold;
  border: 1px solid transparent;
  height: 30px;
  width: 30px;
  text-align: center;
  cursor: pointer;
  transition: color ease-in-out 250ms;
}

.prevNextButton {
  margin-top: 1px;
}

.pagination button.current {
  border: 1px solid #ff0000dd;
}

.pagination button:hover:not(.current, :disabled) {
  color: red;
}

.pagination button:disabled {
  opacity: 0.25;
}
