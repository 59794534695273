.footer {
  border-top: 1px solid gold;
  background-color: #222;
  padding: 1rem;
  color: #fff;
}

.footerNav {
  list-style: none;
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
}

.footerNav a {
  text-decoration: none;
  color: gold;
  text-transform: uppercase;
  font-size: 1.15rem;
  transition: color ease-in-out 250ms;
  font-family: "Bangers", sans-serif;
  letter-spacing: 0.25rem;
}

.socials {
  list-style: none;
  display: flex;
  margin-bottom: 1rem;
  gap: 2rem;
}

.socials li a {
  color: gold;
  font-size: 2rem;
  transition: color ease-in-out 250ms;
}

.footerNav a:hover,
.socials li a:hover {
  color: red;
}

.disclaimer {
  color: #aaa;
}

@media (width >= 768px) {
  .footer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 1rem;
  }

  .disclaimer {
    text-align: center;
    width: 100%;
  }
  .footerNav a {
    font-size: 1rem;
  }
  .socials li a {
    font-size: 1.25rem;
  }
}
