.nav {
  position: fixed;
  z-index: 10;
  background-color: #222;
  height: 55px;
  width: 100%;
  opacity: 0;
  transition: opacity ease-in-out 600ms 100ms;
  pointer-events: none;
}

.nav.scrolled {
  opacity: 1;
  pointer-events: all;
}

.logo {
  position: fixed;
  height: 100px;
  top: 10px;
  left: 10px;
  z-index: 10;
  transition: height ease-in-out 700ms, top ease-in-out 700ms,
    left ease-in-out 700ms;
}

.logo.scrolled {
  top: 0;
  left: 0;
  height: 110px;
}

.navLinks {
  display: none;
  justify-content: flex-end;
  align-items: center;
  margin-right: 2rem;
  font-family: "Bangers", sans-serif;
  letter-spacing: 5px;
  font-size: 1.2rem;
  height: 100%;
  list-style: none;
  gap: 1rem;
  text-transform: uppercase;
}

.navLinks li a {
  text-decoration: none;
  color: gold;
  transition: color ease-in-out 250ms;
}

.navLinks li a:hover {
  color: red;
}

.navLinks li a.active {
  color: red;
}

.menuButtonContainer {
  display: flex;
  justify-content: flex-end;
  margin-right: 1rem;
  margin-top: calc((55px - 35px) / 2);
}

.menuButton {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  background: none;
  border: none;
  height: 35px;
  width: 35px;
  cursor: pointer;
}

.menuBar {
  background-color: gold;
  width: 100%;
  height: 10%;
  box-shadow: 1px 1px 1px 1px #000;
  transition: background-color ease-in-out 250ms, transform ease-in-out 250ms,
    width ease-in-out 100ms;
}

.menuButton.show .menuBar:nth-child(1),
.menuButton.show .menuBar:nth-child(3) {
  background-color: red;
  box-shadow: 1px 1px 1px 1px transparent;
}

.menuButton.show .menuBar:nth-child(1) {
  transform: rotate(45deg) translate(7.5px, 7.5px);
}
.menuButton.show .menuBar:nth-child(3) {
  transform: rotate(-45deg) translate(9px, -9px);
}

.menuButton.show .menuBar:nth-child(2) {
  width: 0;
}

.mobileMenu {
  background-color: #222222ee;
  position: absolute;
  right: -100%;
  top: 55px;
  width: 100%;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  padding: 1rem;
  height: calc(100vh - 55px);
  transition: right ease-in-out 250ms;
}

.mobileMenu.open {
  right: 0;
}

.mobileNavLinks {
  text-align: right;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  list-style: none;
}

.mobileNavLinks a {
  color: gold;
  font-family: "Bangers", sans-serif;
  letter-spacing: 0.2rem;
  text-decoration: none;
  font-size: 2rem;
}

.mobileMenu hr {
  margin: 1rem 0;
  border-color: #333;
  width: 100%;
}

.socials {
  list-style: none;
  display: flex;
  margin-bottom: 1rem;
  gap: 2rem;
}

.socials li a {
  color: gold;
  font-size: 2rem;
  transition: color ease-in-out 250ms;
}
@media (width >= 425px) {
  .navLinks {
    display: flex;
  }

  .menuButtonContainer {
    display: none;
  }

  .mobileMenu {
    display: none;
  }
}
