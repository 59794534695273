.episodeSection {
  background: linear-gradient(180deg, #222, #444);
  background-size: cover;
  background-attachment: fixed;
  padding: 5rem 0 2rem 0;
  min-height: 100vh;
}

.episode {
  margin-top: 3rem;
}

.imageContainer {
  width: 100%;
  margin-bottom: 0.5rem;
}

.imageContainer img {
  width: 100%;
}

.statsMobile {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.5rem;
}

.stats {
  display: none;
}

.pubDate,
.duration {
  color: goldenrod;
}

.title {
  color: gold;
  margin-bottom: 1.5rem;
  font-size: 2rem;
}

.description {
  color: #fff;
  white-space: pre-wrap;
  line-height: 1.4;
}

.description a {
  color: gold;
  text-decoration: none;
  transition: color 0.2s ease-in-out;
}

.description a:hover {
  color: red;
}

.description ul,
.description ol {
  margin-left: 2rem;
}

@media (width >= 768px) {
  .episode {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
  }

  .imageContainer {
    max-width: 100%;
  }

  .title {
    margin-bottom: 0.5rem;
  }

  .statsMobile {
    display: none;
  }

  .stats {
    display: flex;
    gap: 1rem;
    margin-bottom: 1.5rem;
  }
}
