.episodesSection {
  background: linear-gradient(180deg, #222, #444);
  background-size: cover;
  background-attachment: fixed;
  padding: 5rem 0;
  min-height: calc(100vh - 155px);
}

.title {
  color: gold;
  font-size: 2.5rem;
  text-align: center;
  margin: 1rem 0 3.5rem 0;
  padding-bottom: 0.75rem;
  border-bottom: 2px solid red;
}

.sortContainer {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  justify-content: flex-end;
  gap: 1rem;
  margin-bottom: 2rem;
}

.sortContainer p {
  color: #ffd900cc;
  display: inline;
  margin-right: 1rem;
}

.orderButton {
  font-size: 1.1rem;
  background: none;
  border: none;
  color: gold;
  cursor: pointer;
  font-family: "Bangers", sans-serif;
  letter-spacing: 3px;
  transition: background-color ease-in-out 250ms, color ease-in-out 250ms;
}

.orderButton:hover {
  color: #ffd900cc;
}

.episodeTypeSelect {
  border: none;
  font-family: "Bangers", sans-serif;
  letter-spacing: 3px;
  font-size: 1.1rem;
  background-color: transparent;
  color: gold;
}

.episodeTypeSelect option {
  background-color: #333;
  letter-spacing: 3px;
}

.episodes {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 2rem;
  margin-bottom: 2rem;
}

.episode {
  margin-bottom: 2rem;
  box-shadow: 4px 4px 6px -4px #000;
  background-color: #555;
  width: 100%;
  text-decoration: none;
  transition: transform ease-in-out 200ms;
}

.episode:hover {
  transform: scale(1.01);
}

.coverArtContainer {
  position: relative;
  width: 100%;
}

.coverArt {
  display: block;
  width: 100%;
  aspect-ratio: 1 / 1;
  object-fit: cover;
}

.addToList {
  position: absolute;
  bottom: 5px;
  right: 5px;
  padding: 0.25rem;
  background-color: #00000099;
  border: 1px solid gold;
  border-radius: 1px;
  cursor: pointer;
  transition: background-color ease-in-out 250ms;
  display: flex;
  gap: 0.25rem;
  align-items: center;
}

.addToList:hover {
  background-color: gold;
}

.addToList i {
  color: gold;
  font-size: 1.5rem;
  transition: color ease-in-out 250ms, text-shadow ease-in-out 250ms;
}

.addToList :nth-child(2) {
  font-size: 1rem;
}

.addToList:hover i {
  color: #222;
}

.details {
  padding: 1rem;
  border-top: 4px;
}

.name {
  color: gold;
  margin-bottom: 1rem;
  font-size: 1.25rem;
  text-shadow: 2px 2px #000;
  display: block;
}

.excerpt {
  color: #eee;
  font-size: 16px;
  display: block;
}

.pagination {
  display: flex;
  list-style: none;
  justify-content: space-around;
  max-width: 500px;
  margin: 0 auto;
}

.pagination button {
  font-size: 1.25rem;
  background: none;
  border: none;
  color: gold;
  border: 1px solid transparent;
  height: 30px;
  width: 30px;
  text-align: center;
  cursor: pointer;
  transition: color ease-in-out 250ms;
}

.prevNextButton {
  margin-top: 1px;
}

.pagination button.current {
  border: 1px solid #ff0000dd;
}

.pagination button:hover:not(.current, :disabled) {
  color: red;
}

.pagination button:disabled {
  opacity: 0.25;
}

.viewAllLink {
  position: relative;
  text-align: center;
  font-size: 2rem;
  width: max-content;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  transition: transform cubic-bezier(0.77, 0, 0.175, 1) 400ms;
}

.viewAllLink::before {
  content: "";
  position: absolute;
  display: block;
  background-color: red;
  height: 2px;
  width: 0;
  bottom: -5px;
  transition: width ease-in-out 250ms;
}

.viewAllLink:hover {
  transform: rotate(3deg);
}

.viewAllLink:hover::before {
  width: 100%;
}

.viewAllLink a {
  text-decoration: none;
  color: gold;
}

@media (width >= 768px) {
  .episode {
    width: calc(50% - 2rem);
  }

  .sortContainer {
    flex-direction: unset;
  }

  .sortContainer div {
    position: relative;
  }

  .sortContainer div:nth-last-of-type(1):before {
    content: "|";
    position: absolute;
    left: -8px;
    height: 100%;
    width: 2px;
    color: transparent;
    background-color: red;
  }
}

@media (width >= 1024px) {
  .episode {
    width: calc(33.3% - 2rem);
  }
}

@media (width >= 1440px) {
  .episode {
    width: calc(25% - 2rem);
  }
}
