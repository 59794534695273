.hero {
  position: sticky;
  height: 100vh;
  background-color: #333;
  overflow-x: hidden;
  top: 0;
  z-index: -1;
}

.hero .cloud {
  position: absolute;
  height: 75px;
}
.hero .cloud1 {
  top: 20px;
  z-index: 2;
  animation: clouds 120s linear infinite forwards;
}
.hero .cloud2 {
  top: 100px;
  animation: clouds 90s linear infinite forwards;
}
.hero .cloud3 {
  top: 175px;
  animation: clouds 60s linear infinite forwards;
}
.hero .cloud4 {
  top: 225px;
  animation: clouds 40s linear infinite forwards;
}

.buildings {
  position: absolute;
  height: 65%;
  width: 100%;
  object-fit: cover;
  left: 0;
  right: 0;
  bottom: 0;
}

.signal {
  position: relative;
  height: 100px;
}

.neonSign {
  position: absolute;
  top: 65%;
  left: 50%;
  transform: translateX(-50%);
  width: 250px;
  animation: 2s flicker 2s linear forwards infinite;
  filter: grayscale(0%) brightness(20%);
}

@keyframes clouds {
  0% {
    right: calc(0vw - 195px);
  }
  100% {
    right: calc(100vw);
  }
}

@keyframes flicker {
  0%,
  19.999%,
  22%,
  62.999%,
  64%,
  64.999%,
  70%,
  100% {
    filter: grayscale(0%) brightness(100%);
  }
  20%,
  21.999%,
  63%,
  63.999%,
  65%,
  69.999% {
    filter: grayscale(40%) brightness(60%);
  }
}
